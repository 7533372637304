<template>
  <Component :is="vehicleCardType" v-bind="$props" />
</template>

<script setup lang="ts">
import { computed, inject, defineAsyncComponent } from 'vue';
import type { CarCard, VehiclesCatalogSettings } from '~/types';

withDefaults(defineProps<CarCard>(), {
  highlightText: undefined,
  highlightVariant: 'secondary',
});

const type1 = defineAsyncComponent(() => import('~/components/cards/VehicleCardType1.vue'));
const type2 = defineAsyncComponent(() => import('~/components/cards/VehicleCardType2.vue'));
const { vehicleCatalogSettings } = inject('settings') as { vehicleCatalogSettings: VehiclesCatalogSettings['items'] };
const vehicleCardType = computed(() => {
  const mapping: any = {
    1: type1,
    2: type2,
  };
  const vehicleType = vehicleCatalogSettings?.at?.(0)?.card_types || '1';

  return mapping[vehicleType];
});
</script>
